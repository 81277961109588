.tns-outer {
  padding: 0 !important;
}

.tns-outer [hidden] {
  display: none !important;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  transition: all;
}

.tns-slider > .tns-item {
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}

.tns-horizontal.tns-subpixel > .tns-item {
  vertical-align: top;
  white-space: normal;
  display: inline-block;
}

.tns-horizontal.tns-no-subpixel:after {
  content: "";
  clear: both;
  display: table;
}

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  min-height: 1px;
  position: relative;
  left: 0;
}

.tns-gallery > .tns-item {
  transition: transform, opacity;
  position: absolute;
  left: -100%;
}

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}

.tns-gallery > .tns-moving {
  transition: all .25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  opacity: .6;
  transition: opacity .6s;
}

.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  transition: height;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity= 100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity= 0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}

.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  width: 310px;
  height: 10px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.tns-t-ct {
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: 2333.33%;
  position: absolute;
  right: 0;
}

.tns-t-ct:after {
  content: "";
  clear: both;
  display: table;
}

.tns-t-ct > div {
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  float: left;
  width: 1.42857%;
  height: 10px;
}
/*# sourceMappingURL=index.5d3c374a.css.map */
